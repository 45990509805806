import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { SxProps, Theme } from '@mui/material/styles'

import { CustomTheme } from '../types/theme'

interface RDButtonProps extends ButtonProps {
  component?: any // use another alternate component for button
  isIconButton?: boolean //used for grid cells
  blue?: boolean
  light?: boolean
  sx?: SxProps<Theme>
}

function getValidChildren(children) {
  return React.Children.toArray(children).filter((child) => !React.isValidElement(child)) as React.ReactElement[]
}

const childCount = (children) => {
  return React.Children.count(getValidChildren(children))
}

export const RDButton = React.forwardRef((props: RDButtonProps, ref: any) => {
  const { isIconButton, classes, sx = [], blue, light, size, ...rest } = props
  const getButtonStyle = (theme: CustomTheme) => {
    if (blue) {
      return {
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.primary.main,
        color: theme.palette.background.default,
        '&:hover': {
          background: theme.rdPalette.link,
          border: `1px solid ${theme.rdPalette.link}`,
        },
      }
    } else if (light) {
      return {
        background: theme.palette.background.default,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
          background: theme.rdPalette.grey,
          border: `1px solid ${theme.rdPalette.grey}`,
        },
      }
    } else {
      return {}
    }
  }

  return (
    <Button
      ref={ref}
      sx={mergeSx<Theme>(
        (theme) => ({
          border: `1px solid ${(theme as CustomTheme).rdPalette.button_hover}`,
          borderRadius: 16,
          height: 32,
          letterSpacing: 'normal',
          fontSize: '14px',
          minWidth: 32,
          textTransform: 'none',
          padding: childCount(props.children) > 0 ? '3px 6px' : '3px 4px',
          lineHeight: 1.5,
          boxShadow: 'none',
          background: (theme as CustomTheme).rdPalette.button_hover,
          color: theme.palette.secondary.contrastText,
          '&:hover': {
            boxShadow: 'none',
            border: `1px solid ${(theme as CustomTheme).rdPalette.grey}`,
            background: (theme as CustomTheme).rdPalette.grey,
          },
          '&:active': {
            boxShadow: 'none',
            border: `1px solid ${theme.palette.background.paper}`,
          },
          '&:focus': {
            boxShadow: 'none',
            border: `1px solid ${theme.palette.background.paper}`,
          },
          '&.Mui-disabled': {
            backgroundColor: (theme as CustomTheme).rdPalette.button_hover,
            borderColor: (theme as CustomTheme).rdPalette.button_hover,
            opacity: 0.8,
          },
          '& .MuiButton-startIcon': {
            marginRight: childCount(props.children) > 0 ? '4px' : '0px',
            marginLeft: '0px',
          },
        }),
        (theme) => getButtonStyle(theme as CustomTheme),
        sx
      )}
      {...rest}
    />
  )
})

type PureSx<T extends object> = Exclude<SxProps<T>, ReadonlyArray<any>>
type SxAsArray<T extends object> = Array<PureSx<T>>

export const mergeSx = <T extends object>(...styles: (SxProps<T> | false | undefined)[]): SxProps<T> => {
  const capacitor: SxAsArray<T> = []
  for (const sx of styles) {
    if (sx) {
      if (Array.isArray(sx)) {
        for (const sub of sx as SxAsArray<T>) {
          capacitor.push(sub)
        }
      } else {
        capacitor.push(sx as PureSx<T>)
      }
    }
  }
  return capacitor
}
