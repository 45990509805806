import React from 'react'
import { List, ListItem, ListItemButton, ListItemIcon, Tooltip } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { RDFontFamily } from '../../../services/ThemeService'
import { HeaderListInterface, scrollToposition } from '../../TopPane/TopPaneHelpers'
import { ListActionMenuButton } from './ListActionMenuButton'

export const RDList = (props: { list: HeaderListInterface[] }) => {
  const { list } = props
  return (
    <List
      style={{
        color: 'black',
        padding: '2px',
        margin: '0px',
        fontFamily: `"Gotham HTF", "Roboto", "Helvetica Neue", sans-serif`,
        fontSize: '16px',
        maxHeight: '95%',
        overflowY: 'scroll',
      }}>
      {list.map((header, idx) => {
        return (
          <ListItem
            key={header.name}
            alignItems='center'
            style={{ justifyContent: 'space-between', paddingTop: 4, paddingBottom: 4 }}>
            <ListItemIcon
              style={{
                minWidth: 20,
              }}>
              <CircleIcon
                style={{
                  fontSize: 18,
                  marginRight: 0,
                  color: getColor(header.risk?.riskLevel?.trim()?.toLowerCase()),
                }}
              />
            </ListItemIcon>
            <Tooltip
              title={header.risk?.description}
              placement='top'
              enterDelay={2000}
              slotProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#FAFAFA',
                    color: 'black',
                    fontSize: 12,
                    borderRadius: 4,
                    padding: '10px',
                    maxHeight: 100,
                  },
                },
              }}>
              <ListItemButton
                style={{ fontFamily: RDFontFamily, fontSize: 16, marginLeft: 10, padding: 0 }}
                onClick={(evt) => {
                  evt.stopPropagation()
                  scrollToposition(header.headerSearchText)
                }}>
                {header.name?.length > 60 ? `${header.name?.slice(0, 59)}...` : header.name}
              </ListItemButton>
            </Tooltip>
            {header.clause?.[0] && (
              <ListActionMenuButton
                headerItem={header}
                nextHeader={idx === list.length - 1 ? null : list[idx + 1].headerSearchText}
              />
            )}
          </ListItem>
        )
      })}
    </List>
  )
}

const getColor = (riskLevel: string) => {
  switch (riskLevel) {
    case 'low':
      return 'green'
    case 'medium':
      return '#FFEA00'
    case 'high':
      return 'red'
    default:
      return 'grey'
  }
}
