import React from 'react'
import { logoutFromO365 } from '../../utilities/office-apis-helpers'
import { RDButton } from './RDButton'
import { RDPaletteLight } from '../services/ThemeService'
import { AppState } from './AppHelpers'
import { RDGrid } from './Shared/Wrappers/RDGrid'
interface TopMenuBarProps {
  appState: AppState
  appSetState: React.Dispatch<React.SetStateAction<AppState>>
}

// interface TopMenuBarState {
//   instanceAnchorEl: HTMLElement
// }

export const TopMenuBar = (props: TopMenuBarProps) => {
  const { appState, appSetState } = props
  //  const [state, setState] = useState<TopMenuBarState>({ instanceAnchorEl: null })
  const logout = async () => {
    await logoutFromO365(appSetState, appState.userName)
  }

  // const handleOnSubmit = async () => {
  //   const attachmentEditSession = appState.attachmentEditSession
  //   const data = {
  //     attachment_uuid: attachmentEditSession.attachment_uuid,
  //     instance_id: attachmentEditSession.instance_id,
  //     attachment_id: attachmentEditSession.attachment_id,
  //     generation: attachmentEditSession.generation,
  //     file_name: attachmentEditSession.file_name,
  //     sharepoint_item_id: attachmentEditSession.sharepoint_item_id,
  //     __typename: attachmentEditSession.attachment_typename,
  //     mime_type: attachmentEditSession.mime_type,
  //     is_coAuthoring: attachmentEditSession.is_coAuthoring ? 'true' : 'false',
  //     user_id: attachmentEditSession.user_id,
  //   }
  //   try {
  //     let response = await getContractAIData(
  //       `https://devoffice.raindrop.com/saveAttachmentInGCS`,
  //       appState.authToken,
  //       data
  //     )
  //     if (response.data) {
  //       if (response.data.Data?.status !== 200) {
  //         console.log(response)
  //       }
  //     }
  //     appSetState((prev) => ({ ...prev, isSaveDisabled: true, lastSavedAt: new Date() }))
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  return (
    <RDGrid
      id='top-bar-right-container'
      container
      alignItems='center'
      wrap='nowrap'
      justifyContent='space-between'
      style={{
        paddingTop: '0px !important',
        height: 38,
        borderBottomColor: 'lightgrey',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        paddingLeft: 6,
        paddingRight: 6,
        paddingBottom: 0,
        borderLeftWidth: 6,
        borderLeftColor: RDPaletteLight.raindrop_blue,
        borderLeftStyle: 'solid',
        width: '100%',
      }}>
      <RDGrid sx={{ marginLeft: '4px', paddingTop: '4px !important' }}>
        <img height='28px' width='28px' src={'assets/raindrop.png'} alt={'raindrop'} />
      </RDGrid>
      {/* <RDGrid sx={{ marginLeft: '4px', paddingTop: '0px !important' }}>
        <TextField
          variant='standard'
          autoComplete='off'
          disabled={appState?.authStatus !== 'loggedIn'}
          onChange={(evt) => {
            appSetState((prev) => ({ ...prev, filter: evt.target.value }))
          }}
          placeholder='Search Clause'
          value={appState.filter}
        />
      </RDGrid> */}
      <RDGrid sx={{ paddingTop: '0px !important' }}>
        {/* <RDButton
          disabled={appState.isSaveDisabled === undefined ? true : appState.isSaveDisabled}
          sx={{ paddingTop: '0px' }}
          onClick={handleOnSubmit}>{`Save`}</RDButton> */}
      </RDGrid>
      <RDGrid sx={{ paddingTop: '0px !important' }}>
        <RDButton
          disabled={appState?.authStatus !== 'loggedIn'}
          onClick={(event) => {
            event.stopPropagation()
            logout()
          }}>
          {`Sign Out`}
        </RDButton>
      </RDGrid>
      {/* <Menu
        sx={{ '& .MuiPaper-root': { background: '#F5F6F8' }, padding: 0, margin: 0 }}
        data-cy='top-bar-instance-menu'
        anchorEl={state.instanceAnchorEl}
        open={Boolean(state.instanceAnchorEl)}
        onClose={() => setState((prev) => ({ ...prev, instanceAnchorEl: null }))}>
        {appState?.authToken?.length > 0 && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation()
              logout()
            }}>
            {`Logout`}
          </MenuItem>
        )}
      </Menu> */}
    </RDGrid>
  )
}
