import React from 'react'
import { GeminiParsedResponse } from './RDChatBotifyHelpers'
import { RDGrid } from '../RDGrid'
// import ApprovalIcon from '@mui/icons-material/Approval'
import { Typography } from '@mui/material'
import Summary from '../../../Summary'
import ShowChanges from '../../../ShowChanges'
// import { deleteAllContent, generateWordDoc, writeTexttoWordFile } from '../../../../../utilities/office-apis-helpers'

export interface RDChatBotifySummaryProps {
  response: GeminiParsedResponse
  docText: string
}

const RDChatBotifySummary = (props: RDChatBotifySummaryProps) => {
  const { response, docText } = props

  return (
    <RDGrid
      container
      direction={'column'}
      style={{
        fontSize: '16px',
        maxWidth: '100%',
      }}>
      <RDGrid
        container
        direction={'row'}
        justifyContent='space'
        wrap={'nowrap'}
        alignItems='center'
        nopaddingtop='true'>
        <Typography variant='body1' fontWeight={700} fontSize={'16px'}>
          Summary
        </Typography>
        <RDGrid flexGrow={1} nopaddingtop='true'></RDGrid>
        <RDGrid nopaddingtop='true' wrap='nowrap'>
          <ShowChanges
            newMessage={response.updatedContract}
            oldMessage={docText}
            newMessageWithStyles={response.updatedContract}></ShowChanges>
        </RDGrid>
      </RDGrid>
      <Summary summaryText={response.summary}></Summary>
    </RDGrid>
  )
}

export default RDChatBotifySummary
