import React from 'react'
import { diffWords, diffLines } from 'diff' // Import diffWords from 'diff' library
// import { myers } from 'myers-diff'
import * as diff from 'simple-text-diff'
// import { searchAndReplace } from '../../../../utilities/office-apis-helpers'

// Define a component to show the difference between two texts
const TextDiff = ({ oldText, newText }) => {
  function normalizeText(text) {
    const normalizedText = text.replace(/(\r\r|\r|\n\n|\r |\r\r |\n\n |\r\n |\n\r |\n)/g, ' ')
    return normalizedText
  }
  // Use diffWords to get differences between the old and new text

  const diff2 = diffWords(oldText, newText)

  const prepOldTest = normalizeText(oldText)
  const prepNewText = normalizeText(newText)

  const diff1 = diffWords(prepOldTest, prepNewText, ['ignoreWhitespace', 'ignoreAccents'])
  const diffLins = diffLines(prepOldTest, prepNewText)
  const mDeff = diff.default.diffPatchBySeparator(prepOldTest, prepNewText, '.')
  console.log('DIFF-> -> ', diff1)
  console.log('DIFF2-> -> ', diff2)
  console.log('MDIFF-> -> ', diffLins)
  console.log('Hmmmm,', mDeff)
  return (
    <div
      style={{
        fontFamily: `"Gotham HTF", "Roboto", "Helvetica Neue", sans-serif`,
        fontSize: '16px',
      }}>
      {diff1.map((part, index) => {
        const style = {
          color: part.added ? 'green' : part.removed ? 'red' : 'black',
          textDecoration: part.removed ? 'line-through' : 'none',
          backgroundColor: part.added ? '#eaffea' : 'inherit',
        }

        return (
          <>
            {/* <span>{index > 1 ? `...${diff[index - 2].value} ${diff[index - 1].value} ` : null}</span> */}
            <span key={index} style={style}>
              {part.value}
            </span>
            {/* <span>{diff[index + 1]?.value ? `${diff[index + 1].value}... ` : null}</span> */}
          </>
        )

        return null
      })}
    </div>
  )
}

export default TextDiff
