import React, { useEffect, useState } from 'react'
import { generateWordDoc } from '../../utilities/office-apis-helpers'
import { RDGrid } from './Shared/Wrappers/RDGrid'

export interface SummaryProps {
  summaryText: string
}

const Summary = (props: SummaryProps) => {
  const { summaryText } = props
  const [fomattedSummary, setFormattedSummary] = useState<any[]>([])

  useEffect(() => {
    if (summaryText) {
      const formattedText = generateWordDoc(summaryText)
      setFormattedSummary(formattedText)
    }
  }, [summaryText])

  if (fomattedSummary?.length === 0) {
    return null
  }

  return (
    <RDGrid container direction={'column'} style={{ fontSize: '16px' }}>
      {fomattedSummary?.map((para) => {
        if (para.type === 'heading-one') {
          return (
            <RDGrid>
              <h1>{para.children?.[0]?.text}</h1>
            </RDGrid>
          )
        } else if (para.type === 'heading-two') {
          return (
            <RDGrid>
              <h1>{para.children?.[0]?.text}</h1>
            </RDGrid>
          )
        } else if (para.type === 'heading-three') {
          return (
            <RDGrid>
              <h1>{para.children?.[0]?.text}</h1>
            </RDGrid>
          )
        } else {
          return <RDGrid>{para.children?.[0]?.text}</RDGrid>
        }
      })}
    </RDGrid>
  )
}

export default Summary
