import React from 'react'
import { Box, Modal, Tooltip, Typography } from '@mui/material'
import { RDFontFamily } from '../services/ThemeService'
import { RDButton } from './RDButton'
import { Close } from '@material-ui/icons'
import { RDGrid } from './Shared/Wrappers/RDGrid'
import TextDiff from './Shared/Wrappers/RDTextDiff'
import {
  // searchAndInsert,
  searchAndInsertString,
  searchAndReplace,
  searchTextInDoc,
} from '../../utilities/office-apis-helpers'
import { diffWords } from 'diff'

export interface RDDiffProps {
  oldMessage: string
  newMessage: string
  newMessageWithStyles: string
}

const ShowChanges = (props: RDDiffProps) => {
  const [open, setOpen] = React.useState(false)
  //console.log(props)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const getPrevious = (diff: any[], index: number, count: number) => {
    let prev: any
    let prevWord = ''
    if (index > 0) {
      prev = diff[index - 1]
      let untrimmedVal = prev.value
      if (untrimmedVal.trim().length === 0) {
        return getPrevious(diff, index - 1, ++count)
      }
      let prevSplit = prev.value.split(' ')

      if (prevSplit.length > 0) {
        for (let i = prevSplit.length - 1; prevWord.length === 0; i--) {
          prevWord = prevSplit[i]

          if (prevWord.length > 0) {
            let tempStr = ''
            for (let j = 0; j < prevSplit.length - 1 - i + count; j++) {
              tempStr += ' '
            }
            prevWord = prevWord + tempStr
          }
        }
      }
    }
    return prevWord
  }

  const getNext = (diff: any[], index: number, count: number) => {
    let next: any
    console.log('diff[index], diff[index+1]', diff[index], diff[index + 1])
    // let nextWordsArray = []
    if (index > 0) {
      next = diff[index + 1]

      if (next) {
        let untrimmedVal = next.value
        if (untrimmedVal.trim().length === 0) {
          return getNext(diff, index + 1, ++count)
        }
        let nextSplit = next.value.split(' ')
        if (nextSplit.length > 0) {
          let nextVal = ''
          for (let i = 0; i < nextSplit.length; i++) {
            if (nextVal.length === 0) {
              let nextUntrimmedVal = nextSplit[i]
              if (nextUntrimmedVal.trim().length !== 0) {
                nextVal = nextSplit[i]
              }
            }
            break
          }
          return nextVal
          // const count = nextSplit.length < 8 ? nextSplit.length : 8
          // for (let i = 0; i < count; i++) {
          //   nextWord = nextSplit[i]
          //   const nextWordCopy = nextWord
          //   var newString = nextWordCopy.replace(/[^A-Z0-9]/gi, ' ')
          //   if (newString.trim().length !== 0) {
          //     if (nextWord.length > 0) {
          //       let tempStr = ''
          //       for (let j = 0; j < nextWord.length - 1 + i + count; j++) {
          //         tempStr += ' '
          //       }
          //       nextWord = tempStr + nextWord
          //       nextWordsArray.push(nextWordCopy)
          //     }
          //   } else {
          //     nextWordsArray.push(nextWordCopy)
          //   }
          // }
        }
        return ''
      }
      return ''
      // return nextWordsArray
    }
    return ''
  }

  const normalizeText = (text) => {
    const normalizedText = text.replace(/(\r\r|\r|\n\n|\r |\r\r |\n\n |\r\n |\n\r |\n)/g, ' ')
    return normalizedText
  }

  const handleNewTextReplace = async () => {
    // Use diffWords to get differences between the old and new text
    const prepOldTest = normalizeText(props.oldMessage)
    const prepNewText = normalizeText(props.newMessage)

    const diff = diffWords(prepOldTest, prepNewText)
    let skipNext: boolean = false

    const orderedChanges = []

    diff.forEach((part, index) => {
      if (!skipNext) {
        if (part.removed) {
          if (index < diff.length && diff[index + 1].added) {
            const old = {
              ...diff[index],
              prev: getPrevious(diff, index, 0),
              next: getNext(diff, index, 0),
            }
            const newDiff = {
              ...diff[index + 1],
              prev: getPrevious(diff, index + 1, 0),
              next: getNext(diff, index + 1, 0),
            }
            orderedChanges.push({
              id: orderedChanges.length + 1,
              type: 'changed',
              changes: [old, newDiff],
            })

            skipNext = true
          } else {
            const old = {
              ...diff[index],
              prev: getPrevious(diff, index, 0),
            }
            orderedChanges.push({
              id: orderedChanges.length + 1,
              type: 'removed',
              removed: old,
            })
          }
        } else if (part.added) {
          orderedChanges.push({
            id: orderedChanges.length + 1,
            type: 'added',
            added: part,
            next: getNext(diff, index, 0),
          })
        } else {
          var trimmed = part.value.replace(/[^A-Z0-9]/gi, '')
          let unchangedPart = part
          if (trimmed.length > 2) {
            if (part.value.length > 20) {
              unchangedPart.value = part.value.slice(-20)
            } else if (part.value.length > 10) {
              unchangedPart.value = part.value.slice(-10)
            }
            orderedChanges.push({
              id: orderedChanges.length + 1,
              type: 'unchanged',
              unchanged: unchangedPart,
            })
          }
        }
      } else {
        skipNext = false
      }
    })

    let whereAmIInTheDoc = 0 // Index
    console.log('orderedChanges', orderedChanges)

    for (let i = 0; i < orderedChanges.length; i++) {
      console.log('whereAmIInTheDoc', whereAmIInTheDoc)
      let oc = orderedChanges[i]

      if (oc.type === 'changed') {
        let searchText = null
        let replaceText = null
        oc.changes?.forEach(async (c) => {
          if (c.removed) {
            searchText = c.value
          } else if (c.added) {
            replaceText = c.value
          }
        })

        if (searchText && replaceText) {
          const search = await searchTextInDoc(searchText.trim(), whereAmIInTheDoc)
          whereAmIInTheDoc = search.nextHighestIndex > whereAmIInTheDoc ? search.nextHighestIndex : whereAmIInTheDoc
          let indexAfter = search.nextSearchIndexInBody
          console.log(indexAfter)
          await searchAndReplace(searchText.trim(), replaceText, () => {}, indexAfter) //index
        }
      } else if (oc.type === 'removed') {
        let searchText = null
        let replaceText = ''
        searchText = oc.removed?.value?.trim()
        const words = searchText.split(' ')
        for (let i = 0; i < words.length; i++) {
          const word = words[i]
          const search = await searchTextInDoc(word.trim(), whereAmIInTheDoc)
          whereAmIInTheDoc = search.nextHighestIndex > whereAmIInTheDoc ? search.nextHighestIndex : whereAmIInTheDoc
          console.log('whereAmIInTheDoc New', whereAmIInTheDoc, word, search)
          let indexAfter = search.nextSearchIndexInBody
          if (word && word.length > 0) {
            searchAndReplace(word, replaceText, () => {}, indexAfter)
          }
        }
      } else if (oc.type === 'unchanged') {
        // Handle insert
        let searchText = oc.unchanged.value
        const search = await searchTextInDoc(searchText.trim(), whereAmIInTheDoc)
        whereAmIInTheDoc = search.nextHighestIndex > whereAmIInTheDoc ? search.nextHighestIndex : whereAmIInTheDoc
      } else {
        console.log('added ', oc)
        let tempText = oc.next

        const search = await searchTextInDoc(tempText.trim(), whereAmIInTheDoc)
        whereAmIInTheDoc = search.nextHighestIndex > whereAmIInTheDoc ? search.nextHighestIndex : whereAmIInTheDoc
        const indexAfter = search.nextSearchIndexInBody
        console.log('add after index', indexAfter)
        if (tempText.length > 0) {
          searchAndInsertString(tempText.trim(), oc.added.value, null, indexAfter)
        }
      }
    }

    console.log('orderedChanges', orderedChanges)
  }

  return (
    <>
      <Tooltip title='Review'>
        <RDButton style={{ padding: '10px', margin: 5 }} onClick={handleOpen}>
          Review
        </RDButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          sx={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            padding: '20px 30px !important',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '15px',
            zIndex: 2000,
          }}
          textOverflow='scroll'>
          <RDGrid container style={{ margin: 0, padding: 0, height: '100%' }}>
            <RDGrid
              container
              flexGrow={1}
              direction={'row'}
              alignItems={'center'}
              flexWrap={'nowrap'}
              justifyContent={'space-between'}
              style={{ marginRight: 0 }}>
              <Typography fontFamily={RDFontFamily} variant='body1' fontWeight={700} fontSize={'16px'}>
                Changes
              </Typography>
              <RDGrid flexGrow={1}></RDGrid>
              <RDButton
                style={{ color: 'black', padding: '20px', margin: 5 }}
                onClick={(evt) => {
                  evt.stopPropagation()
                  // handleDocxReplace(props.newMessageWithStyles)
                  handleNewTextReplace()
                }}>
                Apply
              </RDButton>
              <RDButton
                style={{ height: 40, width: 40, color: 'black', margin: 0 }}
                onClick={(evt) => {
                  evt.stopPropagation()
                  handleClose()
                }}>
                <Close style={{ height: 20, width: 20 }} />
              </RDButton>
            </RDGrid>
            {/* <ReactDiffViewer
              oldValue={props.oldMessage}
              newValue={props.newMessage}
              splitView={false}
              showDiffOnly={true}
              compareMethod={DiffMethod.SENTENCES}
              hideLineNumbers={true}
            /> */}
            <RDGrid style={{ overflowY: 'scroll', height: '90%' }}>
              <TextDiff oldText={props.oldMessage} newText={props.newMessage}></TextDiff>
            </RDGrid>
          </RDGrid>
        </Box>
      </Modal>
    </>
  )
}

export default ShowChanges
