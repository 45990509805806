import { SxProps, createTheme } from '@mui/material/styles'
import { CustomTheme, RDPaletteColorMap, ThemeName } from './Theme'
import color from 'color'

export const RDPaletteLight: RDPaletteColorMap = {
  //blues
  blue: '#99c2ff',
  blue2: '#97dbff',
  blue3: '#9be8ff',
  blue4: '#90fef7',
  blue5: '#9dc7ee',
  blue6: '#c0e6f8',
  blue7: '#191970',
  //greens
  green: '#90E238',
  green2: '#c5e97d',
  green3: '#7be98c',
  green4: '#8fffdb',
  green5: '#00D100',
  //yellows
  yellow: '#ffe66c',
  yellow2: '#fffb8c',
  //oranges
  orange: '#fec884',
  orange2: '#ffaf7e',
  //reds
  red: '#ff9494',
  red2: '#ff9b85',
  //pinks
  pink: '#ffa6e6',
  pink2: '#ffb1f9',
  //purples
  purple: '#f7c8ff',
  purple2: '#afb2ff',
  purple3: '#c4a6ff',
  purple4: '#aabeff',
  //greys
  grey: '#D6D6D6',
  grey2: '#999999',
  grey3: '#AAAAAA',
  grey4: '#BBBBBB',
  grey5: '#CCCCCC',
  grey6: '#DDDDDD',
  grey7: '#EEEEEE',

  AliceBlue: '#F0F8FF',
  AntiqueWhite: '#FAEBD7',
  Aqua: '#00FFFF',
  Aquamarine: '#7FFFD4',
  Azure: '#F0FFFF',
  Beige: '#F5F5DC',
  Bisque: '#FFE4C4',
  Black: '#000000',
  BlanchedAlmond: '#FFEBCD',
  Blue: '#0000FF',
  BlueViolet: '#8A2BE2',
  Brown: '#A52A2A',
  BurlyWood: '#DEB887',
  CadetBlue: '#5F9EA0',
  Chartreuse: '#7FFF00',
  Chocolate: '#D2691E',
  Coral: '#FF7F50',
  CornflowerBlue: '#6495ED',
  Cornsilk: '#FFF8DC',
  Crimson: '#DC143C',
  Cyan: '#00FFFF',
  DarkBlue: '#00008B',
  DarkCyan: '#008B8B',
  DarkGoldenRod: '#B8860B',
  DarkGray: '#A9A9A9',
  DarkGrey: '#A9A9A9',
  DarkGreen: '#006400',
  DarkKhaki: '#BDB76B',
  DarkMagenta: '#8B008B',
  DarkOliveGreen: '#556B2F',
  DarkOrange: '#FF8C00',
  DarkOrchid: '#9932CC',
  DarkRed: '#8B0000',
  DarkSalmon: '#E9967A',
  DarkSeaGreen: '#8FBC8F',
  DarkSlateBlue: '#483D8B',
  DarkSlateGray: '#2F4F4F',
  DarkSlateGrey: '#2F4F4F',
  DarkTurquoise: '#00CED1',
  DarkViolet: '#9400D3',
  DeepPink: '#FF1493',
  DeepSkyBlue: '#00BFFF',
  DimGray: '#696969',
  DimGrey: '#696969',
  DimWhite: '#dddddd',
  DodgerBlue: '#1E90FF',
  FireBrick: '#B22222',
  FloralWhite: '#FFFAF0',
  ForestGreen: '#228B22',
  Fuchsia: '#FF00FF',
  Gainsboro: '#DCDCDC',
  GhostWhite: '#F8F8FF',
  Gold: '#FFD700',
  GoldenRod: '#DAA520',
  Gray: '#808080',
  Grey: '#808080',
  Green: '#008000',
  GreenYellow: '#ADFF2F',
  HoneyDew: '#F0FFF0',
  HotPink: '#FF69B4',
  IndianRed: '#CD5C5C',
  Indigo: '#4B0082',
  Ivory: '#FFFFF0',
  Khaki: '#F0E68C',
  Lavender: '#E6E6FA',
  LavenderBlush: '#FFF0F5',
  LawnGreen: '#7CFC00',
  LemonChiffon: '#FFFACD',
  LightBlue: '#ADD8E6',
  LightCoral: '#F08080',
  LightCyan: '#E0FFFF',
  LightGoldenRodYellow: '#FAFAD2',
  LightGray: '#D3D3D3',
  LightGrey: '#D3D3D3',
  LightGreen: '#90EE90',
  LightPink: '#FFB6C1',
  LightSalmon: '#FFA07A',
  LightSeaGreen: '#20B2AA',
  LightSkyBlue: '#87CEFA',
  LightSlateGray: '#778899',
  LightSlateGrey: '#778899',
  LightSteelBlue: '#B0C4DE',
  LightYellow: '#FFFFE0',
  Lime: '#00FF00',
  LimeGreen: '#32CD32',
  Linen: '#FAF0E6',
  Magenta: '#FF00FF',
  Maroon: '#800000',
  MediumAquaMarine: '#66CDAA',
  MediumBlue: '#0000CD',
  MediumOrchid: '#BA55D3',
  MediumPurple: '#9370DB',
  MediumSeaGreen: '#3CB371',
  MediumSlateBlue: '#7B68EE',
  MediumSpringGreen: '#00FA9A',
  MediumTurquoise: '#48D1CC',
  MediumVioletRed: '#C71585',
  MidnightBlue: '#191970',
  MintCream: '#F5FFFA',
  MistyRose: '#FFE4E1',
  Moccasin: '#FFE4B5',
  NavajoWhite: '#FFDEAD',
  Navy: '#000080',
  OldLace: '#FDF5E6',
  Olive: '#808000',
  OliveDrab: '#6B8E23',
  Orange: '#FFA500',
  OrangeRed: '#FF4500',
  Orchid: '#DA70D6',
  PaleGoldenRod: '#EEE8AA',
  PaleGreen: '#98FB98',
  PaleTurquoise: '#AFEEEE',
  PaleVioletRed: '#DB7093',
  PapayaWhip: '#FFEFD5',
  PeachPuff: '#FFDAB9',
  Peru: '#CD853F',
  Pink: '#FFC0CB',
  Plum: '#DDA0DD',
  PowderBlue: '#B0E0E6',
  Purple: '#800080',
  RebeccaPurple: '#663399',
  Red: '#FF0000',
  RosyBrown: '#BC8F8F',
  RoyalBlue: '#4169E1',
  SaddleBrown: '#8B4513',
  Salmon: '#FA8072',
  SandyBrown: '#F4A460',
  SeaGreen: '#2E8B57',
  SeaShell: '#FFF5EE',
  Sienna: '#A0522D',
  Silver: '#C0C0C0',
  SkyBlue: '#87CEEB',
  SlateBlue: '#6A5ACD',
  SlateGray: '#708090',
  SlateGrey: '#708090',
  Snow: '#FFFAFA',
  SpringGreen: '#00FF7F',
  SteelBlue: '#4682B4',
  Tan: '#D2B48C',
  Teal: '#008080',
  Thistle: '#D8BFD8',
  Tomato: '#FF6347',
  Turquoise: '#40E0D0',
  Violet: '#EE82EE',
  Wheat: '#F5DEB3',
  White: '#FFFFFF',
  WhiteSmoke: '#F5F5F5',
  Yellow: '#FFFF00',
  YellowGreen: '#9ACD32',

  //status colors
  panelOutline: '#6bbcd1',
  raindrop_purple: '#604B8B',
  raindrop_blue: '#34a1d5',
  raindrop_blue_light: '#81D4FA',
  pageHeaderIconBackground: '#34a1d5',
  paper: '#F5F6F8',
  paper_light: '#FAFAFA',
  background_default: '#ffffff',
  link: '#004991',
  button_hover: '#e9eaec',
  error: '#C80000',
  leftAppBar: '#004991',
  leftDrawerModulesShadow: '#003f82',
  leftDrawerModules: '#004991',
  menuSelected: 'rgb(51 131 213 / 18%)',
  disabled: 'rgb(115 118 125)',
  grid: '#d1f0fd',
  gridSelected: '#b3e3f7',
  gridHover: '#e2f7ff',
  boxShadow: '0px 4px 8px 0px rgba(148, 148, 148, 0.4)',
}
export const RDPaletteDark: RDPaletteColorMap = {
  //blues
  blue: '#12448f',
  blue2: '#10699a',
  blue3: '#0f6985',
  blue4: '#0c837b',
  blue5: '#9dc7ee',
  blue6: '#d9f4fc',
  blue7: color(RDPaletteLight.blue7).darken(0.5).hex(),
  //greens
  green: '#2e7e06',
  green2: '#628c0e',
  green3: '#0c801d',
  green4: '#098860',
  green5: '#00D100',
  //yellows
  yellow: '#997500',
  yellow2: '#a99500',
  //oranges
  orange: '#895200',
  orange2: '#7e3000',
  //reds
  red: '#8e3030',
  red2: '#8a4334',
  //pinks
  pink: '#933c7a',
  pink2: '#8a4b85',
  //purples
  purple: '#522787',
  purple2: '#292d9f',
  purple3: '#3e2c96',
  purple4: '#2b36a7',
  grey: '#6B6B6B',
  grey2: color(RDPaletteLight.grey2).darken(0.5).hex(),
  grey3: '#AAAAAA',
  grey4: '#BBBBBB',
  grey5: '#CCCCCC',
  grey6: '#DDDDDD',
  grey7: '#EEEEEE',

  AliceBlue: color(RDPaletteLight.AliceBlue).darken(0.9).hex(),
  AntiqueWhite: color(RDPaletteLight.AntiqueWhite).darken(0.5).hex(),
  Aqua: color(RDPaletteLight.Aqua).darken(0.5).hex(),
  Aquamarine: color(RDPaletteLight.Aquamarine).darken(0.5).hex(),
  Azure: color(RDPaletteLight.Azure).darken(0.5).hex(),
  Beige: color(RDPaletteLight.Beige).darken(0.5).hex(),
  Bisque: color(RDPaletteLight.Bisque).darken(0.5).hex(),
  Black: color(RDPaletteLight.Black).darken(0.5).hex(),
  BlanchedAlmond: color(RDPaletteLight.BlanchedAlmond).darken(0.5).hex(),
  Blue: color(RDPaletteLight.Blue).darken(0.5).hex(),
  BlueViolet: color(RDPaletteLight.BlueViolet).darken(0.5).hex(),
  Brown: color(RDPaletteLight.Brown).darken(0.5).hex(),
  BurlyWood: color(RDPaletteLight.BurlyWood).darken(0.5).hex(),
  CadetBlue: color(RDPaletteLight.CadetBlue).darken(0.5).hex(),
  Chartreuse: color(RDPaletteLight.Chartreuse).darken(0.5).hex(),
  Chocolate: color(RDPaletteLight.Chocolate).darken(0.5).hex(),
  Coral: color(RDPaletteLight.Coral).darken(0.5).hex(),
  CornflowerBlue: color(RDPaletteLight.CornflowerBlue).darken(0.5).hex(),
  Cornsilk: color(RDPaletteLight.Cornsilk).darken(0.5).hex(),
  Crimson: color(RDPaletteLight.Crimson).darken(0.5).hex(),
  Cyan: color(RDPaletteLight.Cyan).darken(0.5).hex(),
  DarkBlue: color(RDPaletteLight.DarkBlue).darken(0.5).hex(),
  DarkCyan: color(RDPaletteLight.DarkCyan).darken(0.5).hex(),
  DarkGoldenRod: color(RDPaletteLight.DarkGoldenRod).darken(0.5).hex(),
  DarkGray: color(RDPaletteLight.DarkGray).darken(0.5).hex(),
  DarkGrey: color(RDPaletteLight.DarkGrey).darken(0.5).hex(),
  DarkGreen: color(RDPaletteLight.DarkGreen).darken(0.5).hex(),
  DarkKhaki: color(RDPaletteLight.DarkKhaki).darken(0.5).hex(),
  DarkMagenta: color(RDPaletteLight.DarkMagenta).darken(0.5).hex(),
  DarkOliveGreen: color(RDPaletteLight.DarkOliveGreen).darken(0.5).hex(),
  DarkOrange: color(RDPaletteLight.DarkOrange).darken(0.5).hex(),
  DarkOrchid: color(RDPaletteLight.DarkOrchid).darken(0.5).hex(),
  DarkRed: color(RDPaletteLight.DarkRed).darken(0.5).hex(),
  DarkSalmon: color(RDPaletteLight.DarkSalmon).darken(0.5).hex(),
  DarkSeaGreen: color(RDPaletteLight.DarkSeaGreen).darken(0.5).hex(),
  DarkSlateBlue: color(RDPaletteLight.DarkSlateBlue).darken(0.5).hex(),
  DarkSlateGray: color(RDPaletteLight.DarkSlateGray).darken(0.5).hex(),
  DarkSlateGrey: color(RDPaletteLight.DarkSlateGrey).darken(0.5).hex(),
  DarkTurquoise: color(RDPaletteLight.DarkTurquoise).darken(0.5).hex(),
  DarkViolet: color(RDPaletteLight.DarkViolet).darken(0.5).hex(),
  DeepPink: color(RDPaletteLight.DeepPink).darken(0.5).hex(),
  DeepSkyBlue: color(RDPaletteLight.DeepSkyBlue).darken(0.5).hex(),
  DimGray: color(RDPaletteLight.DimGray).darken(0.5).hex(),
  DimGrey: color(RDPaletteLight.DimGrey).darken(0.5).hex(),
  DimWhite: '#cccccc',
  DodgerBlue: color(RDPaletteLight.DodgerBlue).darken(0.5).hex(),
  FireBrick: color(RDPaletteLight.FireBrick).darken(0.5).hex(),
  FloralWhite: color(RDPaletteLight.FloralWhite).darken(0.5).hex(),
  ForestGreen: color(RDPaletteLight.ForestGreen).darken(0.5).hex(),
  Fuchsia: color(RDPaletteLight.Fuchsia).darken(0.5).hex(),
  Gainsboro: color(RDPaletteLight.Gainsboro).darken(0.5).hex(),
  GhostWhite: color(RDPaletteLight.GhostWhite).darken(0.5).hex(),
  Gold: color(RDPaletteLight.Gold).darken(0.5).hex(),
  GoldenRod: color(RDPaletteLight.GoldenRod).darken(0.5).hex(),
  Gray: color(RDPaletteLight.Gray).darken(0.5).hex(),
  Grey: color(RDPaletteLight.Grey).darken(0.5).hex(),
  Green: color(RDPaletteLight.Green).darken(0.5).hex(),
  GreenYellow: color(RDPaletteLight.GreenYellow).darken(0.5).hex(),
  HoneyDew: color(RDPaletteLight.HoneyDew).darken(0.5).hex(),
  HotPink: color(RDPaletteLight.HotPink).darken(0.5).hex(),
  IndianRed: color(RDPaletteLight.IndianRed).darken(0.5).hex(),
  Indigo: color(RDPaletteLight.Indigo).darken(0.5).hex(),
  Ivory: color(RDPaletteLight.Ivory).darken(0.5).hex(),
  Khaki: color(RDPaletteLight.Khaki).darken(0.5).hex(),
  Lavender: color(RDPaletteLight.Lavender).darken(0.5).hex(),
  LavenderBlush: color(RDPaletteLight.LavenderBlush).darken(0.5).hex(),
  LawnGreen: color(RDPaletteLight.LawnGreen).darken(0.5).hex(),
  LemonChiffon: color(RDPaletteLight.LemonChiffon).darken(0.5).hex(),
  LightBlue: color(RDPaletteLight.LightBlue).darken(0.5).hex(),
  LightCoral: color(RDPaletteLight.LightCoral).darken(0.5).hex(),
  LightCyan: color(RDPaletteLight.LightCyan).darken(0.5).hex(),
  LightGoldenRodYellow: color(RDPaletteLight.LightGoldenRodYellow).darken(0.5).hex(),
  LightGray: color(RDPaletteLight.LightGray).darken(0.5).hex(),
  LightGrey: color(RDPaletteLight.LightGrey).darken(0.5).hex(),
  LightGreen: color(RDPaletteLight.LightGreen).darken(0.5).hex(),
  LightPink: color(RDPaletteLight.LightPink).darken(0.5).hex(),
  LightSalmon: color(RDPaletteLight.LightSalmon).darken(0.5).hex(),
  LightSeaGreen: color(RDPaletteLight.LightSeaGreen).darken(0.5).hex(),
  LightSkyBlue: color(RDPaletteLight.LightSkyBlue).darken(0.5).hex(),
  LightSlateGray: color(RDPaletteLight.LightSlateGray).darken(0.5).hex(),
  LightSlateGrey: color(RDPaletteLight.LightSlateGrey).darken(0.5).hex(),
  LightSteelBlue: color(RDPaletteLight.LightSteelBlue).darken(0.5).hex(),
  LightYellow: color(RDPaletteLight.LightYellow).darken(0.5).hex(),
  Lime: color(RDPaletteLight.Lime).darken(0.5).hex(),
  LimeGreen: color(RDPaletteLight.LimeGreen).darken(0.5).hex(),
  Linen: color(RDPaletteLight.Linen).darken(0.5).hex(),
  Magenta: color(RDPaletteLight.Magenta).darken(0.5).hex(),
  Maroon: color(RDPaletteLight.Maroon).darken(0.5).hex(),
  MediumAquaMarine: color(RDPaletteLight.MediumAquaMarine).darken(0.5).hex(),
  MediumBlue: color(RDPaletteLight.MediumBlue).darken(0.5).hex(),
  MediumOrchid: color(RDPaletteLight.MediumOrchid).darken(0.5).hex(),
  MediumPurple: color(RDPaletteLight.MediumPurple).darken(0.5).hex(),
  MediumSeaGreen: color(RDPaletteLight.MediumSeaGreen).darken(0.5).hex(),
  MediumSlateBlue: color(RDPaletteLight.MediumSlateBlue).darken(0.5).hex(),
  MediumSpringGreen: color(RDPaletteLight.MediumSpringGreen).darken(0.5).hex(),
  MediumTurquoise: color(RDPaletteLight.MediumTurquoise).darken(0.5).hex(),
  MediumVioletRed: color(RDPaletteLight.MediumVioletRed).darken(0.5).hex(),
  MidnightBlue: color(RDPaletteLight.MidnightBlue).darken(0.5).hex(),
  MintCream: color(RDPaletteLight.MintCream).darken(0.5).hex(),
  MistyRose: color(RDPaletteLight.MistyRose).darken(0.5).hex(),
  Moccasin: color(RDPaletteLight.Moccasin).darken(0.5).hex(),
  NavajoWhite: color(RDPaletteLight.NavajoWhite).darken(0.5).hex(),
  Navy: color(RDPaletteLight.Navy).darken(0.5).hex(),
  OldLace: color(RDPaletteLight.OldLace).darken(0.5).hex(),
  Olive: color(RDPaletteLight.Olive).darken(0.5).hex(),
  OliveDrab: color(RDPaletteLight.OliveDrab).darken(0.5).hex(),
  Orange: color(RDPaletteLight.Orange).darken(0.5).hex(),
  OrangeRed: RDPaletteLight.OrangeRed,
  Orchid: color(RDPaletteLight.Orchid).darken(0.5).hex(),
  PaleGoldenRod: color(RDPaletteLight.PaleGoldenRod).darken(0.5).hex(),
  PaleGreen: color(RDPaletteLight.PaleGreen).darken(0.5).hex(),
  PaleTurquoise: color(RDPaletteLight.PaleTurquoise).darken(0.5).hex(),
  PaleVioletRed: color(RDPaletteLight.PaleVioletRed).darken(0.5).hex(),
  PapayaWhip: color(RDPaletteLight.PapayaWhip).darken(0.5).hex(),
  PeachPuff: color(RDPaletteLight.PeachPuff).darken(0.5).hex(),
  Peru: color(RDPaletteLight.Peru).darken(0.5).hex(),
  Pink: color(RDPaletteLight.Pink).darken(0.5).hex(),
  Plum: color(RDPaletteLight.Plum).darken(0.5).hex(),
  PowderBlue: color(RDPaletteLight.PowderBlue).darken(0.5).hex(),
  Purple: color(RDPaletteLight.Purple).darken(0.5).hex(),
  RebeccaPurple: color(RDPaletteLight.RebeccaPurple).darken(0.5).hex(),
  Red: color(RDPaletteLight.Red).darken(0.5).hex(),
  RosyBrown: color(RDPaletteLight.RosyBrown).darken(0.5).hex(),
  RoyalBlue: color(RDPaletteLight.RoyalBlue).darken(0.5).hex(),
  SaddleBrown: color(RDPaletteLight.SaddleBrown).darken(0.5).hex(),
  Salmon: color(RDPaletteLight.Salmon).darken(0.5).hex(),
  SandyBrown: color(RDPaletteLight.SandyBrown).darken(0.5).hex(),
  SeaGreen: color(RDPaletteLight.SeaGreen).darken(0.5).hex(),
  SeaShell: color(RDPaletteLight.SeaShell).darken(0.5).hex(),
  Sienna: color(RDPaletteLight.Sienna).darken(0.5).hex(),
  Silver: color(RDPaletteLight.Silver).darken(0.5).hex(),
  SkyBlue: color(RDPaletteLight.SkyBlue).darken(0.5).hex(),
  SlateBlue: color(RDPaletteLight.SlateBlue).darken(0.5).hex(),
  SlateGray: color(RDPaletteLight.SlateGray).darken(0.5).hex(),
  SlateGrey: color(RDPaletteLight.SlateGrey).darken(0.5).hex(),
  Snow: color(RDPaletteLight.Snow).darken(0.5).hex(),
  SpringGreen: color(RDPaletteLight.SpringGreen).darken(0.5).hex(),
  SteelBlue: color(RDPaletteLight.SteelBlue).darken(0.5).hex(),
  Tan: color(RDPaletteLight.Tan).darken(0.5).hex(),
  Teal: color(RDPaletteLight.Teal).darken(0.5).hex(),
  Thistle: color(RDPaletteLight.Thistle).darken(0.5).hex(),
  Tomato: color(RDPaletteLight.Tomato).darken(0.5).hex(),
  Turquoise: color(RDPaletteLight.Turquoise).darken(0.5).hex(),
  Violet: color(RDPaletteLight.Violet).darken(0.5).hex(),
  Wheat: color(RDPaletteLight.Wheat).darken(0.5).hex(),
  White: color(RDPaletteLight.White).darken(0.5).hex(),
  WhiteSmoke: color(RDPaletteLight.WhiteSmoke).darken(0.5).hex(),
  Yellow: color(RDPaletteLight.Yellow).darken(0.5).hex(),
  YellowGreen: color(RDPaletteLight.YellowGreen).darken(0.5).hex(),

  //status colors
  panelOutline: '#005267',
  raindrop_purple: '#604B8B',
  raindrop_blue: '#34a1d5',
  raindrop_blue_light: '#81D4FA',
  pageHeaderIconBackground: '#004991',
  paper: '#424243',
  paper_light: '#878787',
  background_default: '#303031',
  link: RDPaletteLight.DeepSkyBlue,
  button_hover: '#5e5e5f',
  error: '#C80000',
  leftAppBar: '#004991',
  leftDrawerModulesShadow: '#3a3a3a',
  leftDrawerModules: '#424243',
  menuSelected: 'rgb(51 131 213 / 18%)',
  disabled: 'rgb(161 166 176)',
  grid: '#283b4e',
  gridSelected: '#224569',
  gridHover: '#314559',
  boxShadow: '0px 4px 8px 0px rgba(4, 4, 6, 0.4)',
}

export const RDFontFamily = `"Gotham HTF", "Roboto", "Helvetica Neue", sans-serif !important`

const themeCommon: any = {
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: RDFontFamily,
  },
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: 'blue',
            fontWeight: 900,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '14px',
            height: '14px',
            borderRadius: '7px',
            padding: '1px',
            backgroundColor: 'none',
          },
          '*::-webkit-scrollbar-track': { backgroundColor: 'rgba(0,0,0,0.05)' },
          '*::-webkit-scrollbar-thumb': {
            width: '14px',
            height: '14px',
            padding: '1px',
            borderRadius: '7px',
            backgroundColor: 'rgba(0,0,0,0.3)',
          },
          '*': { scrollbarColor: 'rgba(0,0,0,0.3) transparent' },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
        rounded: {
          borderRadius: '12px',
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: 19,
          paddingRight: 16,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
        root: {
          marginTop: 1,
          paddingLeft: 6,
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: RDPaletteLight.grey6,
          '&$active': {
            color: RDPaletteLight.red,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: RDFontFamily,
        },
        body1: {
          fontFamily: RDFontFamily,
        },
        body2: {
          fontFamily: RDFontFamily,
        },
        h1: {
          fontFamily: RDFontFamily,
        },
        h2: {
          fontFamily: RDFontFamily,
        },
        h3: {
          fontFamily: RDFontFamily,
        },
        h4: {
          fontFamily: RDFontFamily,
        },
        h5: {
          fontFamily: RDFontFamily,
        },
        h6: {
          fontFamily: RDFontFamily,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          color: 'red',
        },
      },
    },
    // MuiPickersCalendarHeader: {
    //   styleOverrides: {
    //     switchHeader: {
    //       marginTop: '0px',
    //       marginBottom: '0px',
    //       //color: 'white'
    //     },
    //   },
    // },
    MuiImageListItemBar: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          transition: 'height 0.5s',
          height: '70px',
          '&:hover': {
            height: '100%',
          },
        },
        subtitle: {
          textOverflow: 'unset',
          whiteSpace: 'unset',
        },
      },
    },
    // MuiPickersCalendar: {
    //   styleOverrides: {
    //     transitionContainer: {
    //       marginTop: '0px',
    //     },
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        formControl: {
          minHeight: '32px',
        },
        input: {
          padding: '6px 6px 6px',
          height: 'auto',
        },
        root: {
          lineHeight: '19px',
          '&:hover:not($disabled):not($focused)': {
            background: '#88888840',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          minWidth: '24px',
        },
      },
    },
    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       textDecoration: 'none',
    //       '&.Mui-selected': {
    //         backgroundColor: 'blue',
    //         textDecoration: 'none',
    //       },
    //     },
    //   },
    // },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          height: '36px',
          paddingTop: '0px',
          paddingBottom: '0px',
          paddingLeft: '12px',
          paddingRight: '12px',
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
        },
      },
    },
    // MuiTooltip: {
    //   styleOverrides: {
    //     paper: {},
    //   },
    // },
    MuiCard: {
      styleOverrides: {
        root: {
          marginBottom: '12px',
          boxShadow: 'none',
          borderStyle: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          paper: {
            borderRadius: '8px',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingRight: '12px',
          paddingLeft: '12px',
          paddingTop: '8px',
          paddingBottom: '6px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderStyle: 'solid',
          borderColor: '#cfcfcf',
          borderWidth: 1,
          backgroundColor: 'unset',
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '2px',
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
    },
    // MuiAccordionDetails: {
    //   styleOverrides: {
    //     paper: {
    //       root: {
    //         padding: '2px',
    //       },
    //     },
    //   },
    // },
    // MuiAccordionSummary: {
    //   styleOverrides: {
    //     paper: {
    //       root: {
    //         paddingLeft: '20px',
    //         paddingRight: '20px',
    //         paddingTop: '0px',
    //         paddingBottom: '0px',
    //         '&$focused': {
    //           backgroundColor: null,
    //         },
    //         '&$expanded': {
    //           minHeight: '50px',
    //           maxHeight: '50px',
    //         },
    //         minHeight: '50px',
    //         maxHeight: '50px',
    //       },
    //       content: {
    //         margin: '0px',
    //         minHeight: '50px',
    //       },
    //     },
    //   },
    // },
  },
}

const themeDefs: CustomTheme[] = [
  {
    name: ThemeName.Light,
    isDark: false,
    agGridClassName: 'ag-theme-raindrop',
    fontColor: 'black',
    // @ts-ignore
    palette: {
      mode: 'light',
      //@ts-ignore
      primary: {
        light: RDPaletteLight.raindrop_blue_light,
        main: RDPaletteLight.raindrop_blue,
        dark: '#002984',
      },
      secondary: {
        light: '#c5aef4',
        main: '#a080e0',
        dark: RDPaletteDark.raindrop_purple,
        contrastText: '#000000',
      },
      background: {
        paper: RDPaletteLight.paper,
        default: RDPaletteLight.background_default,
      },
    },
    rdPalette: RDPaletteLight,
  },
  {
    name: ThemeName.Dark,
    fontColor: RDPaletteDark.DimWhite,
    isDark: true,
    agGridClassName: 'ag-theme-raindrop-dark',
    palette: {
      mode: 'dark',
      //@ts-ignore
      primary: {
        light: RDPaletteDark.raindrop_blue_light,
        main: RDPaletteLight.raindrop_blue,
        dark: '#002984',
      },
      //@ts-ignore
      secondary: {
        light: '#c5aef4',
        main: '#a080e0',
        dark: RDPaletteDark.raindrop_purple,
        contrastText: '#cccccc',
      },
      background: {
        paper: RDPaletteDark.paper,
        default: RDPaletteDark.background_default,
      },
    },
    rdPalette: RDPaletteDark,
  },
]

const createCustomMuiTheme = (options: CustomTheme) => {
  return createTheme(Object.assign(options, themeCommon)) as CustomTheme
}

export class ThemeService {
  static getDefaultTheme = () => {
    const themeName = localStorage.getItem('theme') as ThemeName
    if (themeName === ThemeName.Light || themeName === ThemeName.Dark) {
      return ThemeService.getThemeByName(themeName)
    }
    return createCustomMuiTheme(themeDefs[0]) // light
  }

  static getThemes = () => {
    return themeDefs.map((options) => {
      return createCustomMuiTheme(options)
    })
  }

  static getThemeByName = (name: string) => {
    const t = ThemeService.getThemes().filter((theme) => theme.name === name)
    return t[0] ? t[0] : ThemeService.getDefaultTheme()
  }
}

type PureSx<T extends object> = Exclude<SxProps<T>, ReadonlyArray<any>>
type SxAsArray<T extends object> = Array<PureSx<T>>

export const mergeSx = <T extends object>(...styles: (SxProps<T> | false | undefined)[]): SxProps<T> => {
  const capacitor: SxAsArray<T> = []
  for (const sx of styles) {
    if (sx) {
      if (Array.isArray(sx)) {
        for (const sub of sx as SxAsArray<T>) {
          capacitor.push(sub)
        }
      } else {
        capacitor.push(sx as PureSx<T>)
      }
    }
  }
  return capacitor
}
