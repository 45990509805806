import { generateWordDoc } from '../../../../../utilities/office-apis-helpers'
import { GeminiRequestPromptParts } from './RDChatBotify'
import { AxiosResponse } from 'axios'

export const getUserInputType = (userInput: string): 'modify' | 'search' | 'unknown' => {
  const modifyKeywords = ['modify', 'change', 'edit', 'update', 'adjust', 'revise', 'replace', 'rewrite', 'improve']
  const searchKeywords = ['find', 'locate', 'search', 'highlight', 'where', 'scan', 'look for']
  const input = userInput.toLowerCase() // normalize input to lowercase

  // Check if the input contains any modify-related keywords
  if (modifyKeywords.some((keyword) => input.includes(keyword))) {
    return 'modify'
  }

  if (searchKeywords.some((keyword) => input.includes(keyword))) {
    return 'search'
  }
  return 'unknown'
}

export const preprocessUserInput = (text: string) => {
  const preprocessedInput = text.replace(/(\r)/g, '\n')
  return preprocessedInput
}

export const getGeminiRequestPromptParts = (contractDocText: string, userInput: string): GeminiRequestPromptParts => {
  // "Prompt: Answer the question based on the context below.Change termination clause to terminate for convenience.Then genererate contract and send the response under starting with 'contract:' and summarize the changes and send the response  starting with 'summary of change:' and post it in the end of response
  // You are an expert in contract generation, document comparison, document formatting and text replacement. Review the document identified in 'Document:' thoroughly to understand its contents and structure. Your task is to apply instructions under 'Instruction:' on the given 'Document:'. The first step is to generate the document and send the response under 'new document:'.Ensure that the response maintains the original style, design, font and formatting. Ensure 'new document:' is always sent at the top of the response.  The next step is to summarize the changes and sending the response under 'Summary of Changes:' and post it at the end of the response. In the 'Summary of Changes:' do not include any formatting changes. Do not generate the contract and do not summarize if the 'Instruction:' is a question within the context of the 'Document:'. Respond with just the answer and prettify it. Do not generate the contract and do not summarize if the instruction or question is outside the context of the document.Respond with 'I don't understand the question, please rephrase' if the instruction is outside the context of the document. 'Instruction:' change GDPR complaince to low risk
  //  'Instruction:' change my termination to terminate for convenience 'Document:'
  const prefix = `You are an expert in contract generation, document comparison, document formatting and text replacement. Review the document identified in 'Document:' thoroughly to understand its contents and structure. Your task is to apply instructions under 'Instruction:' on the given 'Document:'. The first step is to generate the document and send the response under 'new document:'.Ensure that the response maintains the original style, design, font and formatting. Ensure 'new document:' is always sent at the top of the response.  The next step is to summarize the changes and sending the response under 'Summary of Changes:' and post it at the end of the response. In the 'Summary of Changes:' do not include any formatting changes. Do not generate the contract and do not summarize if the 'Instruction:' is a question within the context of the 'Document:'. Respond with just the answer and prettify it. Do not generate the contract and do not summarize if the instruction or question is outside the context of the document.Respond with 'I don't understand the question, please rephrase' if the instruction is outside the context of the document. 'Instruction:' `
  const suffix = `. 'Document:' `
  return {
    prefix,
    userMessage: userInput,
    suffix,
    document: preprocessUserInput(contractDocText),
  }
}

export interface GeminiParsedResponse {
  type: 'change' | 'new' | 'plain_text'
  original?: string
  revised?: string
  updatedContract?: string
  summary?: string
  added?: string
  plainText?: string
}

export const parseResponse = (response: AxiosResponse): GeminiParsedResponse => {
  let geminiParsedResponse: GeminiParsedResponse
  if (response.status >= 400) {
    geminiParsedResponse = {
      ...geminiParsedResponse,
      type: 'plain_text',
      summary: 'Something went wrong. Please try again.',
    }
  } else {
    const { updatedContract, summary } = extractChangeSummary(response.data)
    geminiParsedResponse = {
      ...geminiParsedResponse,
      summary,
      updatedContract: updatedContract,
    }
  }
  console.log('Gemini Parsed Response', geminiParsedResponse)
  return geminiParsedResponse
}

export const findNewDocumentMarker = (text: string) => {
  const newDocumentMarkers = [
    '**New Document:**',
    '**New Document**',
    'New Document:',
    'New Document',
    '**new document**',
    '**new document**:',
    'new document:',
    'new document',
  ]
  let foundMarker = ''
  newDocumentMarkers.forEach((marker) => {
    if (foundMarker === '') {
      let index = text.indexOf(marker)
      if (index !== -1) {
        foundMarker = marker
      }
    }
  })
  console.log('findNewDocumentMarker', foundMarker)
  return foundMarker
}

export const findNewSummaryOfChangesMarker = (text: string) => {
  const newSummaryOfChangesMarkers = [
    '**Summary of Changes:**',
    '**Summary of Changes**',
    'Summary of Changes:',
    'Summary of Changes:',
    '**summary of Changes:**',
    '**Summary of changes**',
    'summary of changes:',
    'summary of changes:',
  ]
  let foundMarker = ''
  newSummaryOfChangesMarkers.forEach((marker) => {
    if (foundMarker === '') {
      let index = text.indexOf(marker)
      if (index !== -1) {
        foundMarker = marker
      }
    }
  })

  console.log('findNewSummaryOfChangesMarker', foundMarker)
  return foundMarker
}

export const extractChangeSummary = (text) => {
  // Define the starting and ending markers for the summary of change section
  const documentMarker = findNewDocumentMarker(text)
  const summaryOfChangesMarker = findNewSummaryOfChangesMarker(text)

  if (documentMarker === '' || summaryOfChangesMarker === '') {
    return { updatedContract: null, summary: 'Summary of change not found.' }
  }

  let docStartIndex = text.indexOf(documentMarker)
  let summaryOfChangesStartIndex = text.indexOf(summaryOfChangesMarker)

  // Extract the text from the start marker
  const summaryOfChangesStart = summaryOfChangesStartIndex + summaryOfChangesMarker.length
  const docStart = docStartIndex + documentMarker.length
  console.log('startIndexContractMarkerStart', summaryOfChangesStart, docStart)
  const updatedContract = text.substring(docStart, summaryOfChangesStart - summaryOfChangesMarker.length).trim()

  // Find the position of the ending marker after the start of the summary
  const endIndex = text.length
  console.log(endIndex)

  const summary = text.substring(summaryOfChangesStart, endIndex).trim()

  return { updatedContract: updatedContract, summary: summary }
}

export const getDataWithoutStyles = (text: string) => {
  const formattedText = generateWordDoc(text)
  let textWithoutStyles = ''

  JSON.parse(JSON.stringify(formattedText)).forEach((para) => {
    textWithoutStyles += para.children?.[0]?.text + ' '
    // if (para.type === 'heading-one') {
    //   // writeTexttoWordFile(para.children?.[0]?.text, null, 18, true)
    //   textWithoutStyles += '<h1>para.children?.[0]?.text</h1><br/>'
    // } else if (para.type === 'heading-two') {
    //   textWithoutStyles += '<h2>{para.children?.[0]?.text}</h2><br/>'
    // } else if (para.type === 'heading-three') {
    //   textWithoutStyles += '<b>para.children?.[0]?.text</b><br/>'
    // } else {
    //   textWithoutStyles += 'para.children?.[0]?.text<br/>'
    // }
  })

  return textWithoutStyles
}

export const getDataWihStyles = (text: string) => {
  const formattedText = generateWordDoc(text)
  let textWithoutStyles = ''

  JSON.parse(JSON.stringify(formattedText)).forEach((para) => {
    // textWithoutStyles += para.children?.[0]?.text + ' '
    if (para.type === 'heading-one') {
      // writeTexttoWordFile(para.children?.[0]?.text, null, 18, true)
      textWithoutStyles += `<RDGrid><h1>${para.children?.[0]?.text}</h1><br/></RDGrid>`
    } else if (para.type === 'heading-two') {
      textWithoutStyles += `<RDGrid><h2>${para.children?.[0]?.text}</h2><br/></RDGrid>`
    } else if (para.type === 'heading-three') {
      textWithoutStyles += `<RDGrid><b>${para.children?.[0]?.text}</b><br/></RDGrid>`
    } else {
      textWithoutStyles += `<RDGrid>${para.children?.[0]?.text}<br/></RDGrid>`
    }
  })

  return textWithoutStyles
}
